let BASE_PATH = "https://disrupt-bab-api.disrupt.art";
let API_PATH = `${BASE_PATH}/api/v1`;
let MIDWEST_API_PATH = "https://midwestcon-api.hyperbig.com"
export const configPath = {
    baseUrl: BASE_PATH,
    apiUrl: API_PATH,
    eventId : 1,
    midwestApi: MIDWEST_API_PATH,
    stripe_pkey: 'pk_test_51PcnTsRq0Ib6bGmwZikS9wLNj9TZmyxCzKswEp4dA8RfNOFJvc82eWQvwWobkZz0vGItlapRWZvRWq1mANDX7yz700Nxije3qw'
}
