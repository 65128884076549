import React, { useEffect } from 'react';
import { PopupButton } from '@typeform/embed-react';


function DataHeader() {

    const scrollToSection = (target) => {
        const targetElement = document.querySelector(target);
        if (targetElement) {
            window.scrollTo({
                top: targetElement.offsetTop,
                behavior: 'smooth'
            });
        }
    };

    const handleLinkClick = (e, target) => {
        e.preventDefault();
        scrollToSection(target);
    };

    return (
        <header>
            <nav className="navbar navbar-expand-lg fixed-top mwc-navbar">
                <div className="container">
                    <a className="navbar-brand animated animatedFadeInUp fadeInUp" href="/">
                        <img src={'/assets/img/midwestcon-logo-new.png'} alt="" className="mw-100" />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mb-lg-0 ms-auto">
                            <li className="nav-item animated2 animatedFadeInUp fadeInUp">
                                <button href="#section1" className="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">THE FUTURE OF DATA HACKATHON 2024</button>
                            </li>
                            <li className="nav-item animated2 animatedFadeInUp fadeInUp">
                                <button href="#section1" className="nav-link" onClick={(e) => handleLinkClick(e, '#apply')} data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">APPLY</button>
                            </li>
                            <li className="nav-item animated2 animatedFadeInUp fadeInUp">
                                <button href="#section1" className="nav-link" onClick={(e) => handleLinkClick(e, '#spons')} data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">SPONSORS</button>
                            </li>

                            <li className="nav-item animated2 animatedFadeInUp fadeInUp">
                                <PopupButton id='ks6FSj5D' className="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">VOLUNTEER</PopupButton>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export { DataHeader };
