import React, { useState } from 'react';
import moment from 'moment';
import { PopupButton } from '@typeform/embed-react'
import { Link, useNavigate } from "react-router-dom";
import { GetTicket } from './GetTicket';

const Modal = ({ handleClose, show }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";

    return (
        <div className={showHideClassName}>
            <section className="modal-main">
                {/* <button onClick={handleClose}>Close</button> */}
                <h1>To announce later.</h1>
            </section>
        </div>
    );
};

function Header() {

    const scrollToSection = (target) => {
        const targetElement = document.querySelector(target);
        if (targetElement) {
            window.scrollTo({
                top: targetElement.offsetTop,
                behavior: 'smooth'
            });
        }
    };

    const handleLinkClick = (e, target) => {
        e.preventDefault();
        scrollToSection(target);
    };

    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => {
        setShowModal(!showModal);

        setTimeout(() => {
            setShowModal(false);
        }, 5000);
    };

    const [openTicketPopup, setOpenTicketPopup] = useState(false);
    
    const closeTicketPopup = () => {
        setOpenTicketPopup(false)
    }

    const handleOpenTicketPopup = () => {
        setOpenTicketPopup(true)
    }

    return (
        <nav className="navbar navbar-expand-lg fixed-top mwc-navbar">
            <div className="head_container">
                <div className='logo'>
                    <a className="navbar-brand animated animatedFadeInUp fadeInUp" href="/">
                        <img src={'/assets/img/midwestcon-logo-new.png'} alt="" className="mw-100" />
                    </a>
                    <div className='sub_logo'>
                        <span>POWERD by</span>
                        <a className="navbar-brand animated animatedFadeInUp fadeInUp" href="">
                            <img src={'/assets/img/disruption-now.jpg'} alt="" className="mw-100" />
                        </a>
                        <a className="navbar-brand animated animatedFadeInUp fadeInUp" href="">
                            <img src={'/assets/img/for_our_logo.jpg'} alt="" className="mw-100" />
                        </a>
                    </div>
                </div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mb-lg-0 ms-auto">
                        <li className="nav-item animated2 animatedFadeInUp fadeInUp">
                            <button href="#section1" className="nav-link" onClick={(e) => handleLinkClick(e, '#about')} data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">About</button>
                        </li>
                        <li className="nav-item animated2 animatedFadeInUp fadeInUp">
                            <a href="/datahackathon-2024" target='_blank' className="nav-link">Future of data hackathon</a>
                        </li>
                        {/* <li className="nav-item animated2 animatedFadeInUp fadeInUp">
                            <PopupButton id="LQxuq0mC" className="btn">
                                Get Ticket
                            </PopupButton>
                        </li> */}
                        <li className="nav-item animated2 animatedFadeInUp fadeInUp">
                            <button href="#section1" className="nav-link" onClick={(e) => handleOpenTicketPopup()} data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Get Ticket</button>
                        </li>
                        <li className="nav-item animated2 animatedFadeInUp fadeInUp">
                            <button href="#section1" className="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={toggleModal}>Agenda</button>
                        </li>
                        <li className="nav-item animated2 animatedFadeInUp fadeInUp">
                            <button href="#section1" className="nav-link" onClick={(e) => handleLinkClick(e, '#get')} data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Get Involved</button>
                        </li>
                        
                    </ul>
                </div>
            </div>
            <Modal show={showModal} handleClose={toggleModal} />
            <GetTicket isOpen={openTicketPopup} closeTicketPopup={closeTicketPopup}/>
        </nav>

    );
}

export { Header };
