import React, { useEffect, useState, useRef } from "react";
import moment from 'moment';
import { PopupButton } from '@typeform/embed-react';
import { Modal, Button, Carousel, Accordion } from "react-bootstrap";
import MyVerticallyCenteredModal from './policy_modal';
import MyVerticallyCenteredModal2 from "./creator_modal";
import MyVerticallyCenteredModal3 from "./workforce_modal";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useLocation } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Textslider from "./textslider";
import SpeakerLineup from "./speaker_lineup";
import PrevSpeaker from "./prev_speakers";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { GetTicket } from "./GetTicket";

let client_form_url = "https://disruptionnow.us14.list-manage.com/subscribe/post?u=ae99ac9a4fafd8609771595ff&id=de0b7cbc51&f_id=00d582e1f0"

function NewMidwestcon() {
    const currentYear = moment().year()
    const [openNotifyForm, setOpenNotifyForm] = useState(false)
    const closeNotifyForm = () => {
        setOpenNotifyForm(false)
    }
    const openForm = () => {
        // setOpenNotifyForm(true)
        window.open(client_form_url, '_blank')

    }
    useEffect(() => {

        document.addEventListener("submit", () => {

            window.location.reload()
        });

    })

    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })

    const [modalShow, setModalShow] = React.useState(false);
    const [modalShow2, setModalShow2] = React.useState(false);
    const [modalShow3, setModalShow3] = React.useState(false);

    useEffect(() => {

        AOS.init({
            easing: 'ease-out-back',
            duration: 1000
        });

    }, [])
    const location = useLocation()

    useEffect(() => {
        if (location && location.pathname === '/' && location.state?.myProp) {
            const newState = { ...location.state };
            delete newState.myProp;
            const element = document.getElementById(location.state?.myProp);
            if (location.hash) {
                const targetDiv = document.querySelector(location.hash);
                if (targetDiv) {
                    setTimeout(() => {
                        targetDiv.scrollIntoView({ behavior: 'smooth' });

                    }, 100)
                }
            }

        }

    }, [location])

    const options = {
        margin: 30,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: true,
        slideTransition: 'linear',
        autoplayTimeout: 1800,
        autoplaySpeed: 2500,
        autoplayHoverPause: false,
        responsive: {
            0: {
                items: 2,
            },
            481: {
                items: 3,
            },
            600: {
                items: 3,
            },
            767: {
                items: 3,
            },
            768: {
                items: 4,
            },
            1200: {
                items: 6
            }
        },
    };
    const options2 = {
        rtl: true,
        margin: 30,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: true,
        slideTransition: 'linear',
        autoplayTimeout: 1800,
        autoplaySpeed: 2500,
        autoplayHoverPause: false,
        responsive: {
            0: {
                items: 1,
            },
            575: {
                items: 2,
            },
            600: {
                items: 2,
            },
            767: {
                items: 3,
            },
            768: {
                items: 4,
            },
            1200: {
                items: 6
            }
        },

    };


    const options3 = {

        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: false,
        slideTransition: 'linear',
        autoplayTimeout: 5000,
        autoplaySpeed: 2500,
        autoplayHoverPause: false,
        responsive: {
            0: {
                items: 1,
            },
            575: {
                items: 2,
            },
            600: {
                items: 2,

            },
            768: {
                items: 3,
                margin: 50,
            },
            991: {
                items: 3,
                margin: 75,
            },
            1025: {
                items: 4,
                margin: 75,
            },
            1200: {
                margin: 75,
                items: 4,
            }
        },

    };

    const options4 = {
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: false,
        slideTransition: 'linear',
        autoplayTimeout: 5000,
        autoplaySpeed: 2500,
        autoplayHoverPause: false,
        responsive: {
            0: {
                items: 1,
            },
            575: {
                items: 2,
            },
            600: {
                items: 2,

            },
            768: {
                items: 3,
                margin: 50,
            },
            991: {
                items: 3,
                margin: 20,
            },
            1025: {
                items: 4,
                margin: 15,
            },
            1200: {
                margin: 15,
                items: 4,
            }
        },

    };

    const items2 = [
        { image: "/assets/img/ps2023/2022_2_10_AftabP_79289.jpg", name: "Mayor Aftab Pureval", role: "Mayor of Cincinnati" },
        { image: "/assets/img/ps2023/CharisseDean.jpeg", name: "Charisse Dean", role: "Managing Director KPMG US ESG Social Strategy" },
        { image: "/assets/img/ps2023/DavidLondon.jpeg", name: "David London", role: "Head of US State & Local Public Policy Coinbase" },
        { image: "/assets/img/ps2023/Barbara-Bickham-421X343.png", name: "Barbara Bickham", role: "Fund Manager Trailyn Ventures" },
        { image: "/assets/img/ps2023/kalagibson.jpg", name: "Kala Gibson", role: "Executive Vice President Fifth Third Bank" },
        { image: "/assets/img/ps2023/kalistaZackhariyas.jpg", name: "Kalista Zackhariyas", role: "Founder/CEO Sparkseeker" },
        { image: "/assets/img/ps2023/prespinto.jpeg", name: "Neville G. Pinto PhD", role: "President University of Cincinnati" },
        { image: "/assets/img/ps2023/Shira.png", name: "Shira Lazar", role: "Emmy Nominated Host Former Host for Inside Web3" },
    ];

    const items = [
        { link: "#", image: "/assets/img/ps2024/Mayor_Aftab_Pureval.jpg", name: "Mayor Aftab Pureval", role: "Mayor of", company: "Cincinnati" },
        { link: "https://www.linkedin.com/in/mjofcc/", image: "/assets/img/ps2024/BrandonKnight.jpeg", name: "Brandon Knight", role: "Global Head of Customer Experience", company: "Zoom" },
        { link: "https://www.linkedin.com/in/paul-grewal-288978b4/", image: "/assets/img/ps2024/paulgrewal.jpg", name: "Paul Grewal", role: "Chief Legal Officer", company: "Coinbase" },
        { link: "https://www.linkedin.com/in/noelleai/", image: "/assets/img/ps2024/NoelleRussell.jpg", name: "Noelle Russell", role: "Chief AI Officer", company: "AI Leadership Institute" },
        { link: "https://www.linkedin.com/in/tsavoknott/", image: "/assets/img/ps2024/Tsavo_Knott.jpg", name: "Tsavo Knott", role: "Technical Co-Founder/CEO", company: "Pieces.app" },
        { link: "https://www.linkedin.com/in/christopher-gray-5b335626", image: "/assets/img/ps2024/Chris_Gray.jpg", name: "Christopher Gray", role: "Founder/CEO", company: "Scholly, Inc." },
        { link: "https://www.linkedin.com/in/abbymcinturfdesign/", image: "/assets/img/ps2024/Abby_McInturf.jpg", name: "Abby McInturf", role: "Co-Founder", company: "Band Connect" },
        { link: "https://www.linkedin.com/in/david-bianchi-4362406/", image: "/assets/img/ps2024/david_b.jpg", name: "David Bianchi", role: "Actor/Producer Founder", company: "Exertion3 Films" },
        { link: "https://www.linkedin.com/in/kahncept", image: "/assets/img/ps2024/Adam_Kahn.jpg", name: "Adam Kahn", role: "Chief Creative Officer", company: "Grey Midwest" },
        { link: "https://www.linkedin.com/in/robrichardsonjr/", image: "/assets/img/ps2024/rob_headshot.jpg", name: "Rob Richardson", role: "Curator MidwestCon/Founder", company: "Disrupt Now" },
        { link: "https://www.linkedin.com/in/tim-ryan-756a954a/", image: "/assets/img/ps2024/RepTimRyan.jpg", name: "Tim Ryan", role: "Former OHIO Representative", company: "" },
        // { link: "https://www.linkedin.com/in/kerelcooper/", image: "/assets/img/ps2024/kerel.jpg", name: "Kerel Cooper", role: "President of Advertising", company: "Group Black" },
        { link: "https://www.linkedin.com/in/helentodd/", image: "/assets/img/ps2024/helenTodd.jpeg", name: "Helen Todd", role: "Founder", company: "Creativity Squared" },
        { link: "https://www.linkedin.com/in/odai-ammar/", image: "/assets/img/ps2024/OdaiAmmar.jpeg", name: "Odai Ammar", role: "Co-Founder", company: "Liquid Prop" },
        { link: "https://www.linkedin.com/in/robert-gordon-2a19771/", image: "/assets/img/ps2024/robertgordon.jpg", name: "Robert Gordon III", role: "Senior Strategic Leader for", company: "AI and Digital Innovation DSS Inc" },
        { link: "https://www.linkedin.com/in/kelly-cohen-a51b851a/", image: "/assets/img/ps2024/Dr_Kelly_Cohen.jpg", name: "Dr Kelly Cohen", role: "Brian H. Rowe Endowed Chair & Director", company: "AI Bio Lab Digital Futures UC" },
        // { link: "https://www.linkedin.com/in/timrettig/", image: "/assets/img/ps2024/Tim-Rettig-2023-Intrust-IT.jpg", name: "Tim Rettig", role: "CEO", company: "Intrust IT" },
        { link: "https://www.linkedin.com/in/claude-cimeus-37550874/", image: "/assets/img/ps2024/ClaudCimeus.jpeg", name: "Claude Cimeus", role: "Director of", company: "Platform P33 Chicago" },
        // { link: "https://www.linkedin.com/in/patrickhaney/", image: "/assets/img/ps2024/patrickhaney.jpg", name: "Patrick Haney", role: "Product Designer", company: "AGNT" },
        // { link: "https://www.linkedin.com/in/jennamarino/", image: "/assets/img/ps2024/JennaMarino.jpg", name: "Jenna Marino", role: "Founder/Designer", company: "AGNT" },
        { link: "https://www.linkedin.com/in/david-lopez-kurtz/", image: "/assets/img/ps2024/David-Lopez-Kurtz.png", name: "David Lopez-Kurtz", role: "Tech Attorney", company: "Croke Fairchild Duarte & Beres LLC" },
        { link: "https://www.linkedin.com/in/mosbackr/", image: "/assets/img/ps2024/LeeMosbacker.jpg", name: "Lee Mosbacker", role: "Founder", company: "Cyrannus" },
        { link: "https://www.linkedin.com/in/sharufawalker/", image: "/assets/img/ps2024/Sharufa-Rashied-Walker.jpg", name: "Sharufa Rashied-Walker", role: "Co-Founder/CEO", company: "Welcome Home International Realty Group" },
        { link: "https://www.linkedin.com/in/amypvaughan/", image: "/assets/img/ps2024/AmyVaughn.jpg", name: "Amy Vaughn", role: "CEO", company: "Together Digital" },
        // { link: "https://www.linkedin.com/in/anilrathi/", image: "/assets/img/ps2024/AnilRathi.jpeg", name: "Anil Rathi", role: "Founder/CEO", company: "Skild" },
        { link: "https://www.linkedin.com/in/privacy-evangelist/", image: "/assets/img/ps2024/John_Cavanaugh.jpg", name: "John Cavanaugh", role: "Executive Director", company: "The Plunk Foundation" },
        { link: "https://www.linkedin.com/in/lisafrancoeur/", image: "/assets/img/ps2024/Lisa_Francoeur.jpg", name: "Lisa Fancyfied Francoeur", role: "Co-Founder/CRO", company: "Crypto Tutors" },
        { link: "https://www.linkedin.com/in/evan-karnoupakis-bb52317/", image: "/assets/img/ps2024/Evan_Karnoupakis.jpg", name: "Evan Karnoupakis", role: "Blockchain Author NFTs", company: "The Metaverse and Everything Web 3.0" },
        { link: "https://www.linkedin.com/in/TyrellSmith", image: "/assets/img/ps2024/ty_smith.jpg", name: "Ty Smith", role: "Founder/CEO", company: "Optical Diversity" },
        { link: "https://www.linkedin.com/in/johnsonxanthony/", image: "/assets/img/ps2024/Anthony_Johnson.jpg", name: "Anthony Johnson", role: "Corporate Strategy & Marketing", company: "" },
        { link: "https://www.linkedin.com/in/timmetzner/", image: "/assets/img/ps2024/Tim_Metzner.jpg", name: "Tim Metzner", role: "CEO", company: "Fireroad" },
        { link: "https://www.linkedin.com/in/megan-hymore/", image: "/assets/img/ps2024/Megan_Hymore.jpg", name: "Megan Hymore", role: "Venture Attorney", company: "Dinsmore & Shohl LLP" },
        { link: "https://www.linkedin.com/in/link-green-31510314a/", image: "/assets/img/ps2024/Link_Green.jpg", name: 'Link "The Khemist" Green', role: "Global Emerging Tech & Web3 Educator", company: "Coindesk Spokesperson & Ops Mgr $DESK" },
        { link: "http://linkedin.com/in/fritzhand", image: "/assets/img/ps2024/Jeremy_Fritzhand.jpg", name: "Jeremy Fritzhand", role: "Startup Catalyst", company: "" },
        { link: "https://www.linkedin.com/in/jammontgomery/", image: "/assets/img/ps2024/Jamar_Montgomery.jpg", name: "Jamar “Doc” Montgomery", role: "Program Manager", company: "Boeing" },
        { link: "http://www.linkedin.com/in/matthew-sias-91b2a339", image: "/assets/img/ps2024/Matthew_Sias.jpg", name: "Matthew Sias", role: "Founder", company: "InnovationAcceleration.ai" },
        { link: "https://www.linkedin.com/in/claytonbanks/", image: "/assets/img/ps2024/Clayton_Banks.jpg", name: "Clayton Banks", role: "CEO", company: "Silicon Harlem" },
        { link: "https://www.linkedin.com/in/mgleveille/", image: "/assets/img/ps2024/Genevieve_1.jpg", name: "Genevieve Leveille", role: "Founder/CEO", company: "AgriLedger" },
        { link: "https://www.linkedin.com/in/deepaksekar/", image: "/assets/img/ps2024/Deepak.jpg", name: "Deepak Sekar", role: "Co-Founder/CEO", company: "Prof Jim" },
        { link: "https://www.linkedin.com/in/consumergeneratedmedia/", image: "/assets/img/ps2024/PeteBlackshaw.jpg", name: "Pete Blackshawa", role: "Founder/CEO", company: "BrandRank.AI" },
        // { link: "https://www.linkedin.com/in/debbieareynolds/", image: "/assets/img/ps2024/DebbieReynolds.jpg", name: "Debbie Reynolds “The Data Diva”", role: "Founder/CEO", company: "Debbie Reynolds Consulting" },
        { link: "https://www.linkedin.com/in/jessgem/", image: "/assets/img/ps2024/JessicaGaston.jpg", name: "Jessica Gaston", role: "Founder", company: " The Finance Doctor LLC" },
        { link: "https://www.linkedin.com/in/charron-leeper-58987714/", image: "/assets/img/ps2024/CharronLeeper.jpeg", name: "Charron Leeper", role: "Co-Founder/Creative Director", company: "Futureland" },
        { link: "", image: "/assets/img/ps2024/Jackson_III.jpg", name: "Learwinson Jackson III", role: "Executive Program Directo", company: "The S.T.O.P. Squad All Boys Web3 & Blockchain Academy" },
        { link: "https://www.linkedin.com/in/marquanjones31/", image: "/assets/img/ps2024/Marquan_Jones.jpg", name: "Marquan Jones", role: "President", company: "Chicago Fashion Coalition" },
        { link: "https://www.linkedin.com/in/marwig/", image: "/assets/img/ps2024/Merry_Marwig.jpg", name: "Merry Marwig", role: "Co-Founder", company: "The Privacy Exchange Community" },
        { link: "https://www.linkedin.com/in/dr-j-c-baker-51216b113/", image: "/assets/img/ps2024/Dr_J_C_Baker.jpg", name: "Dr. J.C. Baker", role: "Owner", company: "The Business Hospital" },
        // { link: "https://www.linkedin.com/in/joshua-ogundu-b92b835b/", image: "/assets/img/ps2024/Joshua_Ogundu.jpg", name: "Joshua Ogundu", role: "Founder/CEO", company: "Tradeway" },
        { link: "https://www.linkedin.com/in/johnbcreating/", image: "/assets/img/ps2024/John_Johnson.jpg", name: "John Johnson", role: "Founder/Principal & Identity Architect", company: "A Small Studio" },
        // { link: "http://www.linkedin.com/in/karora007", image: "/assets/img/ps2024/Kumar_Arora.jpg", name: "Kumar Arora", role: "CEO", company: "Arora Ventures" },
        { link: "https://www.linkedin.com/in/kalistabecoming/", image: "/assets/img/ps2024/Kalista_Zackhariyas.jpg", name: "Kalista Zackhariyas", role: "Founder/CEO", company: "Sparkseeker" },
        { link: "https://www.linkedin.com/in/kemoaakhutera/", image: "/assets/img/ps2024/K_akhutera.jpg", name: "Kémo A’akhutera", role: "Founder/CEO", company: "Trep House & Design To Build" },
        { link: "https://www.linkedin.com/in/camrynellis2/", image: "/assets/img/ps2024/Camyrn-Ellis.jpg", name: "Camryn Ellis", role: "Founder/CEO", company: "RYN" },
        // { link: "https://www.linkedin.com/in/acmceo/", image: "/assets/img/ps2024/Patrick_Hill.jpg", name: "Patrick Hill", role: "Founder/CEO", company: "Disctopia" },
        { link: "https://www.linkedin.com/in/iyanuodebode/", image: "/assets/img/ps2024/Iyanuoluwa_Odebode.jpg", name: "Iyanuoluwa Odebode", role: "CEO/Chief Data Scientist", company: "Zeitios" },
        { link: "https://www.linkedin.com/in/angeline-corvaglia-52b4a86/", image: "/assets/img/ps2024/angeline.jpg", name: "Angeline Corvaglia", role: "Founder", company: "Data Girl and Friends" },
        { link: "https://www.linkedin.com/in/john-delay-24ab5a/", image: "/assets/img/ps2024/John_Delay.jpg", name: "John Delay", role: "Founder/CEO", company: "Geospatial Sciences Services" },
        { link: "https://www.linkedin.com/in/zaria-smith-0a31b6170?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app", image: "/assets/img/ps2024/Zaria_Smith.jpg", name: "Zaria Smith", role: "Program Director", company: "Nex Cubed" },
        // { link: "https://www.linkedin.com/in/naama-o-pozniak-69b5913/", image: "/assets/img/ps2024/Naama_headshot.jpg", name: "Naama O. Pozniak", role: "Founder/CEO", company: "LetzGetPhygital" },
        { link: "https://www.linkedin.com/in/cyrus-harbin-6a0892163/", image: "/assets/img/ps2024/Cyrus.jpg", name: "Cyrus Harbin", role: " Founder", company: "Tech Is The New Black" },
        { link: "https://www.linkedin.com/in/marc-minor-a734887/", image: "/assets/img/ps2024/Marc_Minor.jpg", name: "Marc Minor", role: " Senior Counsel", company: "Thompson Hine LLP" },
        { link: "https://www.linkedin.com/in/chrysatheodore/", image: "/assets/img/ps2024/Chrysa_Theodore.jpg", name: "Chrysa Theodore", role: "VP", company: "Venture Operations" },
        { link: "https://www.linkedin.com/in/ericinnyc/", image: "/assets/img/ps2024/Eric_Solomon.jpg", name: "Eric Solomon, Ph.d", role: "Founder/CEO", company: "The Human OS™" },
        { link: "https://www.linkedin.com/in/damuwinston/", image: "/assets/img/ps2024/Damu_Winston.jpg", name: "Damu Winston", role: "Global Head Web 3", company: "Amazon" },
        { link: "https://www.linkedin.com/in/lenajjennings/", image: "/assets/img/ps2024/Lena_Jennings.jpg", name: "Lena Jennings", role: "Data Story Teller", company: "Google" },
        { link: "http://linkedin.com/in/adetayoadesanya", image: "/assets/img/ps2024/Tayo_Adesanya.jpg", name: "Tayo Adesanya", role: "Founder/CEO", company: "Lola Vision Systems" },
        { link: "http://linkedin.com/in/tracyslee", image: "/assets/img/ps2024/tracy_lee.jpg", name: "Tracy Lee", role: "CEO", company: "This Dot Labs" },
        { link: "https://www.linkedin.com/in/borisoglesby/", image: "/assets/img/ps2024/Boris_Oglesby.jpg", name: "Boris Oglesby", role: "Executive VP & Practice Leader", company: "Circana" },
        { link: "https://www.linkedin.com/in/maximserezhin/", image: "/assets/img/ps2024/Maxim_Serezhin.jpg", name: "Maxim Serezhin", role: "Founder/CEO", company: "Standard Power" },
        { link: "https://www.linkedin.com/in/rodneybwilliams/", image: "/assets/img/ps2024/rodney_williams.jpg", name: "Rodney Williams", role: "Co-Founder", company: "Solo Funds" },
    ];
    const [openTicketPopup, setOpenTicketPopup] = useState(false);
    
    const closeTicketPopup = () => {
        setOpenTicketPopup(false)
    }
    const handleOpenTicketPopup = () => {
        setOpenTicketPopup(true)
    }
    return (
        <div className="App">
            <Header />
            <GetTicket isOpen={openTicketPopup} closeTicketPopup={closeTicketPopup}/>
            <Modal show={openNotifyForm} onHide={(e) => closeNotifyForm(e, this)} dialogClassName="animationDialog" className="asc-popup" aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body>

                    <div id="mc_embed_signup">
                        <form action="https://disruptionnow.us14.list-manage.com/subscribe/post?u=ae99ac9a4fafd8609771595ff&amp;id=de0b7cbc51&amp;f_id=00d582e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate>
                            <div id="mc_embed_signup_scroll">
                                <h4>Get Tickets Now</h4>
                                <h2>about MidWestCon 2024</h2>
                                <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>


                                <div className="mc-field-group">
                                    <label for="mce-EMAIL">Email Address  <span class="asterisk">*</span></label>
                                    <input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required />
                                    <span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span>
                                </div>
                                <div hidden={true}><input type="hidden" name="tags" value="783114" /></div>

                                <div id="mce-responses" class="clear">
                                    <div class="response" id="mce-error-response" style={{ display: "none" }}></div>
                                    <div class="response" id="mce-success-response" style={{ display: "none" }}></div>
                                </div>
                                <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true"><input type="text" name="b_ae99ac9a4fafd8609771595ff_de0b7cbc51" tabindex="-1" /></div>
                                <div class="clear d-flex mb-3 justify-content-end"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button" /></div>
                            </div>


                        </form>
                        <Button className="clsbtn" onClick={closeNotifyForm}>X</Button>
                    </div>

                </Modal.Body>

            </Modal>
            {/* 
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />

            <MyVerticallyCenteredModal2
                show={modalShow2}
                onHide={() => setModalShow2(false)}
            />
            <MyVerticallyCenteredModal3
                show={modalShow3}
                onHide={() => setModalShow3(false)}
            /> */}

            <div className="mwc-banner ">
                <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active overlaybg">
                            <img className="animated animatedFadeInUp fadeInUp" src={'/assets/img/6C5A6519_crop.jpg'} alt="" />
                            <div className="mwc-con text-center">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-5">
                                            <div className="btnbottomalign">
                                                <h1 className="animated2 animatedFadeInUp fadeInUp">
                                                    September 4-6, 2024
                                                    <span>1819 Innovation Hub, UC<br />2900 Reading Rd, Cincinnati OH</span>
                                                </h1>
                                                {/* <PopupButton id="LQxuq0mC" style={{ fontSize: 20 }} className="btn btn-primary btn-custom animated animatedFadeInUp fadeInUp" data-aos="fade-up">
                                                    <span data-aos="fade-up" data-aos-duration="1500">Get Ticket</span>
                                                </PopupButton> */}
                                                <button className="btn btn-primary btn-custom animated animatedFadeInUp fadeInUp" onClick={(e) => handleOpenTicketPopup()} data-aos="fade-up" > <span data-aos="fade-up" data-aos-duration="1500">Get Ticket</span></button>

                                                {/* <a href="https://www.eventbrite.com/e/midwestcon-2024-tickets-710598889407" style={{ fontSize: 20 }} className="btn btn-primary btn-custom animated animatedFadeInUp fadeInUp" data-aos="fade-up" target="_blank">
                                                    <span data-aos="fade-up" data-aos-duration="1500">Stay Informed</span>
                                                </a> */}
                                            </div>
                                        </div>
                                        <div className="col-7">
                                            <span className="yeartxt">
                                                {/* <img src="/assets/img/mwclogo2.png" alt="" className="me-0" /> */}
                                                <svg class="Playground__svg">
                                                    <defs>
                                                        <filter id="filter" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="linearRGB">

                                                            <feMorphology operator="dilate" radius="10 0" x="0%" y="0%" width="100%" height="100%" in="SourceGraphic" result="morphology1"></feMorphology>
                                                        </filter>
                                                        <filter id="filter-2" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="linearRGB">

                                                            <feMorphology operator="dilate" radius="10 2" x="0%" y="0%" width="100%" height="100%" in="SourceGraphic" result="morphology1"></feMorphology>
                                                        </filter>
                                                        <filter id="filter-3" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="linearRGB">

                                                            <feMorphology operator="dilate" radius="15 0" x="0%" y="0%" width="100%" height="100%" in="SourceGraphic" result="morphology1"></feMorphology>
                                                        </filter>
                                                    </defs>
                                                    <image width="100%" height="100%" preserveAspectRatio="xMidYMid slice" href="https://midwestcon.live/assets/img/mwclogo2.png" id="my-image"></image>
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="py60" id="c-banner" >
                <div className="container">
                    {/* <div className="row">
                        <div className="col-md-7" data-aos="fade-up" data-aos-duration="1000">
                            <h2 className="pfdf40">WHERE POLICY MEETS<br /><span className="clrfffbf9">INNOVATION</span>,<br /> CREATORS IGNITE <span className="clrfffbf9">CHANGE</span> &<br /> TECH FUELS <span className="clrfffbf9">SOCIAL IMPACT</span>.</h2>
                            <div className="imgh400">
                                <img src={'/assets/img/innovation.png'} alt="" className="mw-100" />
                            </div>
                        </div>
                        <div className="col-md-5" data-aos="fade-down" data-aos-duration="1000">
                            <div className="revengwrap">
                                <div className="imgh350">
                                    <img src={'/assets/img/reverse-engineering.png'} alt="" className="mw-100" />
                                </div>
                                <h3 className="pfdf40 pfdf36">We’re <span className="clrfffbf9 fst-italic">reverse-engineering</span><br />the way conferences are<br />being done.</h3>
                            </div>
                        </div>

                    </div> */}
                    <div className="v-head">.
                        <h4 class="pfdf40 pfdf45 mb50 fst-italic aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">OWNERSHIP & <span class="clrfffbf9">AI JOIN THE MOVEMENT</span></h4>
                    </div>
                    <div className="v-body">
                        <div className="row">
                            <div className="col-md-6">
                                <video width="640" height="360" controls poster={require('../assets/video/video_thumb.png')}>
                                    <source src={require('../assets/video/videoplayback.mp4')} type="video/mp4"/>
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <div className="col-md-6">
                                <p>Access to digital literacy, data ownership, and skills in AI and emerging technologies is the new wave of freedom and opportunity. Join us to develop your AI, blockchain, and data visualization skills while learning about the latest technologies around design, policy, and social impact. </p>
                            </div>
                        </div>
                    </div>
                    <div className="v-foot">
                        <div className="row">
                            <div className="col-md-4">
                                <h4>1000+</h4>
                                <p>Students</p>
                            </div>
                            <div className="col-md-4">
                                <h4>500+</h4>
                                <p>Industry Professionals</p>
                            </div>
                            <div className="col-md-4">
                                <h4>60+</h4>
                                <p>Workshops & Speakers</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="py60 pt-0 mapwrap">
                <div className="container">
                    <h3 className="text-end pfdf40 pfdf45 pe-50 pb60" data-aos="fade-up" data-aos-duration="1000">WHY THE <span className="clrfffbf9">MIDWEST?</span></h3>
                    <h3 className="pfdf40 pfdf36 text-uppercase mb50" data-aos="fade-down" data-aos-duration="1000">We <span className="clrfffbf9 fst-italic">champion</span> the Midwest, especially Ohio, as the epicenter of a new wave of <span className="clrfffbf9 fst-italic">responsible innovation</span> and home to <span className="clrfffbf9 fst-italic">top tier</span> talents and experts.</h3>
                </div>
                <div className="nmapbg" id="ticket_info">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6" data-aos="fade-up" data-aos-duration="1000">
                                {/* <div className="imgh4001">
                                <img src={'/assets/img/map-midwestcon.png'} alt="" className="mw-100" />
                                <div className="mapiconsvg">
                                    <img src={'/assets/img/map-mark.png'} alt="" className="mw-100" />
                                    <img src={'/assets/img/map-bottom.png'} alt="" className="mw-100" />
                                </div>
                            </div> */}
                            </div>
                            <div className="col-md-6" data-aos="fade-down" data-aos-duration="1000">
                                <div className="revengwrap mt200">
                                    <Textslider />
                                </div>
                                <Accordion className="new-accor ticket_info" data-aos="fade-up" data-aos-duration="1000">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Ticket Details</Accordion.Header>
                                        <Accordion.Body>
                                            <h4>GENERAL ADMISSION + WORKSHOP</h4>
                                            <p>Your General Pass includes:</p>
                                            <p>Conference access, including Hackathon September 4 -6 (3 days)</p>
                                            <ul>
                                                <li>Complimentary General Workshop Access during MidwestCon</li>
                                                <li>Access to all floors, speaker tracks, and Q&A sessions</li>
                                                <li>Access to Early June hands-on workshops building AI tools with Microsoft experts on a first-come-first-come basis. </li>
                                            </ul>
                                            <h4>VIP PASS + WORKSHOP</h4>
                                            <p>Your VIP Pass includes everything in General Admission PLUS:</p>
                                            <ul>
                                                <li>Early workshop Access for MidwestCon</li>
                                                <li>VIP Access to Keynote sessions</li>
                                                <li>Exclusive Access to the VIP lounge at the conference</li>
                                                <li>Official conference T-shirt & Swag Bag</li>
                                                <li>Exclusive VIP Disruption Mixer w/ speakers hosted by CincyAI Meet</li>
                                                <li>Exclusive MidwestCon 2024 VIP welcome gift</li>
                                                <li>Networking one-on-one matching with potential leads, jobs, or partners.</li>
                                            </ul>
                                            <h4>Student GENERAL ADMISSION + WORKSHOP</h4>
                                            <p>Your General Pass includes:</p>
                                            <ul>
                                                <li>Conference access, including Hackathon September 4 -6 (3 days)</li>
                                                <li>Complimentary General Workshop Access during MidwestCon</li>
                                                <li>Access to all floors, speaker tracks, and Q&A sessions</li>
                                                <li>Access to Early June hands-on workshops building AI tools with Microsoft experts on a first-come-first-come basis.</li>
                                                <li>Access to meeting with potential employers</li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Ticket Release Schedule</Accordion.Header>
                                        <Accordion.Body>
                                            <p>May 13th - June 7th: Early Disruptor $95 and $10 Student</p>
                                            <p>June 7th- July 5th Innovator (GA $135, $25 Student and VIP $200)
                                            </p>
                                            <p>July 6th -August 5th Procrastinator (GA $165, Student $50 and VIP $250)
                                            </p>
                                            <p>August 6th-August 30th: Last Damn Minute (GA $195, Student $75 and VIP $300)
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                <div className="get_btn">
                                    {/* <PopupButton id="LQxuq0mC" style={{ fontSize: 20 }} className="btn btn-primary btn-custom animated animatedFadeInUp fadeInUp" data-aos="fade-up">
                                        <span data-aos="fade-up" data-aos-duration="1500">Get Tickets Now</span>
                                    </PopupButton> */}
                                    <button className="btn btn-primary btn-custom animated animatedFadeInUp fadeInUp" onClick={(e) => handleOpenTicketPopup()} data-aos="fade-up"><span data-aos="fade-up" data-aos-duration="1500">Get Tickets Now</span></button>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="mwc-banner imgcenter h-auto mh-auto" id="about">
                {/* <img src={'/assets/img/really.png'} alt="" className="imgcenterimg" /> */}
                <div className="container">
                    <div className="mw-1140" data-aos="fade-down" data-aos-duration="1000">
                        {/* <h4 className="pfdf40 mb-0">We’re tired of the same set-ups, the same topics, and we <span className="clrfffbf9 fst-italic">really</span> hate fake connections that only last until the end of a conference.</h4> */}
                        <h2><span>MidwestCon</span>, the policy innovation conference for</h2>
                        <ul>
                            <li>
                                <div className="icns">
                                    <img src={'/assets/img/m_icon1.png'} alt="" />
                                </div>
                                <div className="txt">
                                    <h4>Tech & AI Curious, Builders & Beginners</h4>
                                </div>
                            </li>
                            <li>
                                <div className="icns">
                                    <img src={'/assets/img/m_icon2.png'} alt="" />
                                </div>
                                <div className="txt">
                                    <h4>Policy Advocates</h4>
                                </div>
                            </li>
                            <li>
                                <div className="icns">
                                    <img src={'/assets/img/m_icon3.png'} alt="" />
                                </div>
                                <div className="txt">
                                    <h4>Entrepreneurs & Inspring Entrepreneurs</h4>
                                </div>
                            </li>
                            <li>
                                <div className="icns">
                                    <img src={'/assets/img/m_icon4.png'} alt="" />
                                </div>
                                <div className="txt">
                                    <h4>Designers & Creators</h4>
                                </div>
                            </li>
                            <li>
                                <div className="icns">
                                    <img src={'/assets/img/m_icon5.png'} alt="" />
                                </div>
                                <div className="txt">
                                    <h4>Marketing & Branding Professionals</h4>
                                </div>
                            </li>
                            <li>
                                <div className="icns">
                                    <img src={'/assets/img/m_icon6.png'} alt="" />
                                </div>
                                <div className="txt">
                                    <h4>Blockchain Builders</h4>
                                </div>
                            </li>
                            <li>
                                <div className="icns">
                                    <img src={'/assets/img/m_icon7.png'} alt="" />
                                </div>
                                <div className="txt">
                                    <h4>Developers</h4>
                                </div>
                            </li>
                            <li>
                                <div className="icns">
                                    <img src={'/assets/img/m_icon8.png'} alt="" />
                                </div>
                                <div className="txt">
                                    <h4>Students & Educators  </h4>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="py60" >
                <div className="container">
                    {/* <h3 className="pfdf40 mb50 text-center" data-aos="fade-down" data-aos-duration="1000">At <span className="clrfffbf9">MidwestCon</span>, we want our connections to be <span className="clrfffbf9 fst-italic">future</span> opportunities and empowering <span className="clrfffbf9 fst-italic">communities.</span></h3> */}
                    <h3 className="pfdf40 mb50 text-center" data-aos="fade-down" data-aos-duration="1000">At <span className="clrfffbf9">MidwestCon</span>, we embrace authentic impact over gimmicks.</h3>
                    <div className="threeimgbox">
                        <div className="row">
                            <div className="col-sm-4" data-aos="fade-up" data-aos-duration="1000"><img src={'/assets/img/meet1.png'} alt="" /></div>
                            <div className="col-sm-4 text-center" data-aos="fade-up" data-aos-duration="1500"><img src={'/assets/img/meet2.png'} alt="" className="brdrtop" /></div>
                            <div className="col-sm-4 text-end" data-aos="fade-up" data-aos-duration="2000"><img src={'/assets/img/meet3.png'} alt="" /></div>
                        </div>
                    </div>
                    <h4 className="pfdf40 pfdf36 mt95" data-aos="fade-down" data-aos-duration="1000"><span className="clrfffbf9">Through our tailored</span> IRL collaborations<span className="clrfffbf9">, our</span> gamification of learning<span className="clrfffbf9">, and our focus on</span> social impact<span className="clrfffbf9">– we’re the bridge...</span></h4>
                </div>
            </div>

            <div className="py60" id="fodh">
                <div className="container">
                    <h2>Future of
                        <span>Data</span>
                        <span>Hackathon</span>
                    </h2>
                    <p>Join hundreds of builders in the Midwest for a week of hacking emerging tech solutions, insightful workshops, networking, and a Demo Day. If you have never hacked before or are not technical, no worries! We have beginner tracks for AI and blockchain.</p>
                    <a href="/datahackathon-2024" target="_blank" className="btn btn-primary btn-custom animated animatedFadeInUp fadeInUp data_btn" data-aos="fade-up">
                        <span data-aos="fade-up" data-aos-duration="1500">Learn more</span>
                    </a>
                </div>
            </div>

            <div className="py60 brownbgsec">
                <div className="container">
                    <h4 className="pfdf40 pfdf36" data-aos="fade-down" data-aos-duration="1000">...connecting the brightest minds in <span className="clrfffbf9 fst-italic">policy, venture capital, law, emerging technology</span>, and the <span className="clrfffbf9 fst-italic">creative industry</span>. </h4>
                    {/* <h4 className="pfdf40 pfdf45 mt95 mb50 fst-italic" data-aos="fade-up" data-aos-duration="1000">PREVIOUS <span className="clrfffbf9">SPEAKERS</span></h4> */}

                    {/* <div className="speaker_slider _desktop">
                        <OwlCarousel className='owl-theme3' loop margin={0}  {...options3} nav={true}>
                            <div class='item'>
                                <div className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2023/2022_2_10_AftabP_79289.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Mayor Aftab Pureval <br /><br />Mayor of Cincinnati</h4>
                                </div>
                            </div>

                            <div class='item'>
                                <div className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2023/CharisseDean.jpeg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Charisse Dean<br /><br />
                                        Managing Director<br />
                                        KPMG US ESG Social Strategy</h4>
                                </div>
                            </div>

                            <div class='item'>
                                <div className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2023/DavidLondon.jpeg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">David London<br /><br />
                                        Head of US State & Local Public Policy<br />
                                        Coinbase</h4>
                                </div>
                            </div>

                            <div class='item'>
                                <div className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2023/Barbara-Bickham-421X343.png'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Barbara Bickham<br /><br />
                                        Fund Manager<br />
                                        Trailyn Ventures</h4>
                                </div>
                            </div>

                            <div class='item'>
                                <div className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2023/kalagibson.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Kala Gibson<br /><br />
                                        Executive Vice President<br />
                                        Fifth Third Bank
                                    </h4>
                                </div>
                            </div>

                            <div class='item'>
                                <div className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2023/kalistaZackhariyas.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Kalista Zackhariyas<br /><br />

                                        Founder/CEO<br />
                                        Sparkseeker
                                    </h4>
                                </div>
                            </div>

                            <div class='item'>
                                <div className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2023/prespinto.jpeg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Neville G. Pinto PhD<br /><br />
                                        President<br />
                                        University of Cincinnati
                                    </h4>
                                </div>
                            </div>

                            <div class='item'>
                                <div className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2023/Shira.png'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Shira Lazar<br /><br />
                                        Emmy Nominated Host<br />
                                        Former Host for Inside Web3
                                    </h4>
                                </div>
                            </div>
                        </OwlCarousel>

                    </div>
                    <div className="speaker_lineup _mobile">
                        <PrevSpeaker itemsToShowInitially2={8} itemsPerLoad2={100} items2={items2} />
                    </div> */}



                    {/* <div className="btnwithtxt pt60">
                        <h4 className="pfdf40 pfdf36 me-5" data-aos="fade-down" data-aos-duration="1000"><span className="clrfffbf9 fst-italic">Apply</span> to be a</h4>
                        <PopupButton id="OT6PQTFD" style={{ fontSize: 20 }} className="btn btn-primary btn-custom animated animatedFadeInUp fadeInUp disabled">
                            <span data-aos="fade-up" data-aos-duration="1500">Speaker</span>
                        </PopupButton>
                    </div> */}
                </div>
            </div>

            <div className="py60 brownbgsec" id="new_speaker">
                <div className="container">

                    <h4 className="pfdf40 pfdf45 mb50 fst-italic" data-aos="fade-up" data-aos-duration="1000">THIS YEAR’S <span className="clrfffbf9">LINE UP...</span></h4>

                    <div className="speaker_slider _desktop">
                        <OwlCarousel className='owl-theme3' loop margin={0}  {...options4} nav={true}>
                            <div class='item'>
                                <a href="#" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Mayor_Aftab_Pureval.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Mayor Aftab Pureval<br /><br />
                                        Mayor of <br />Cincinnati
                                    </h4>
                                </a>
                                <a href="https://www.linkedin.com/in/mjofcc/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/BrandonKnight.jpeg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Brandon Knight<br /><br />
                                        Global Head of Customer Experience<br />Zoom
                                    </h4>
                                </a>
                            </div>
                            <div class='item'>
                                <a href="https://www.linkedin.com/in/paul-grewal-288978b4/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/paulgrewal.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Paul Grewal<br /><br />
                                        Chief Legal Officer<br /> Coinbase</h4>
                                </a>
                                <a href="https://www.linkedin.com/in/noelleai/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/NoelleRussell.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Noelle Russell<br /><br />
                                        Chief AI Officer<br />AI Leadership Institute</h4>
                                </a>
                            </div>
                            <div class='item'>
                                <a href="https://www.linkedin.com/in/tsavoknott/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Tsavo_Knott.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Tsavo Knott<br /><br />
                                        Technical Co-Founder/CEO<br /> Pieces.app
                                    </h4>
                                </a>
                                <a href="https://www.linkedin.com/in/christopher-gray-5b335626" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Chris_Gray.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Christopher Gray<br /><br />
                                        Founder/CEO<br /> Scholly, Inc.
                                    </h4>
                                </a>
                            </div>
                            <div class='item'>
                                <a href="https://www.linkedin.com/in/abbymcinturfdesign/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Abby_McInturf.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Abby McInturf<br /><br />
                                        Co-Founder<br /> Band Connect
                                    </h4>
                                </a>
                                <a href="https://www.linkedin.com/in/david-bianchi-4362406/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/david_b.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">David Bianchi<br /><br />
                                        Actor/Producer Founder <br /> Exertion3 Films
                                    </h4>
                                </a>
                            </div>
                            <div class='item'>
                                <a href="https://www.linkedin.com/in/kahncept" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Adam_Kahn.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Adam Kahn<br /><br />
                                        Chief Creative Officer<br /> Grey Midwest
                                    </h4>
                                </a>
                                <a href="https://www.linkedin.com/in/robrichardsonjr/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/rob_headshot.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Rob Richardson<br /><br />
                                        Curator MidwestCon/Founder Disrupt Now</h4>
                                </a>
                            </div>
                            <div class='item'>
                                <a href="https://www.linkedin.com/in/tim-ryan-756a954a/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/RepTimRyan.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Tim Ryan <br /><br />
                                        Former OHIO Representative</h4>
                                </a>
                                {/* <a href="https://www.linkedin.com/in/kerelcooper/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/kerel.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Kerel Cooper <br /><br />
                                        President of Advertising<br />Group Black</h4>
                                </a> */}
                                <a href="https://www.linkedin.com/in/robert-gordon-2a19771/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/robertgordon.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Robert Gordon III<br /><br />
                                        Senior Strategic Leader for AI and Digital Innovation<br /> DSS Inc
                                    </h4>
                                </a>
                            </div>
                            <div class='item'>

                                <a href="https://www.linkedin.com/in/helentodd/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/helenTodd.jpeg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Helen Todd<br /><br />
                                        Founder<br /> Creativity Squared</h4>
                                </a>
                                <a href="https://www.linkedin.com/in/odai-ammar/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/OdaiAmmar.jpeg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Odai Ammar <br /><br />
                                        Co-Founder<br />
                                        Liquid Prop</h4>
                                </a>
                            </div>
                            <div class='item'>

                                <a href="https://www.linkedin.com/in/kelly-cohen-a51b851a/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Dr_Kelly_Cohen.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Dr Kelly Cohen<br /><br />
                                        Brian H. Rowe Endowed Chair & Director <br /> AI Bio Lab  <br /> Digital Futures  <br /> UC
                                    </h4>
                                </a>
                                <a href="https://www.linkedin.com/in/claude-cimeus-37550874/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/ClaudCimeus.jpeg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Claude Cimeus<br /><br />
                                        Director of Platform<br />
                                        P33 Chicago
                                    </h4>
                                </a>
                            </div>
                            {/* <div class='item'>
                                <a href="https://www.linkedin.com/in/timrettig/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Tim-Rettig-2023-Intrust-IT.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Tim Rettig<br /><br />
                                        CEO<br />
                                        Intrust IT
                                    </h4>
                                </a>    
                            </div> */}
                            {/* <div class='item'>
                                <a href="https://www.linkedin.com/in/patrickhaney/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/patrickhaney.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Patrick Haney<br /><br />
                                        Product Designer<br /> AGNT
                                    </h4>
                                </a>
                                <a href="https://www.linkedin.com/in/jennamarino/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/JennaMarino.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Jenna Marino<br /><br />
                                        Founder/Designer<br /> AGNT
                                    </h4>
                                </a>
                            </div> */}
                            <div class='item'>
                                <a href="https://www.linkedin.com/in/david-lopez-kurtz/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/David-Lopez-Kurtz.png'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">David Lopez-Kurtz<br /><br />
                                        Tech Attorney<br />Croke Fairchild Duarte & Beres LLC
                                    </h4>
                                </a>
                                <a href="https://www.linkedin.com/in/mosbackr/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/LeeMosbacker.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Lee Mosbacker<br /><br />
                                        Founder<br />Cyrannus
                                    </h4>
                                </a>
                            </div>
                            <div class='item'>
                                <a href="https://www.linkedin.com/in/amypvaughan/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/AmyVaughn.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Amy Vaughn<br /><br />
                                        CEO<br /> Together Digital
                                    </h4>
                                </a>
                                {/* <a href="https://www.linkedin.com/in/anilrathi/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/AnilRathi.jpeg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Anil Rathi<br /><br />
                                        Founder/CEO<br /> Skild
                                    </h4>
                                </a> */}
                                <a href="https://www.linkedin.com/in/privacy-evangelist/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/John_Cavanaugh.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">John Cavanaugh<br /><br />
                                        Executive Director<br />The Plunk Foundation
                                    </h4>
                                </a>
                            </div>
                            <div class='item'>

                                <a href="https://www.linkedin.com/in/lisafrancoeur/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Lisa_Francoeur.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Lisa Fancyfied Francoeur<br /><br />
                                        Co-Founder/CRO <br />Crypto Tutors
                                    </h4>
                                </a>
                                <a href="https://www.linkedin.com/in/evan-karnoupakis-bb52317/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Evan_Karnoupakis.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Evan Karnoupakis<br /><br />
                                        Blockchain Author "NFTs <br /> The Metaverse and Everything Web 3.0"
                                    </h4>
                                </a>
                            </div>
                            <div class='item'>

                                <a href="https://www.linkedin.com/in/sharufawalker/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Sharufa-Rashied-Walker.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Sharufa Rashied-Walker<br /><br />
                                        Co-Founder/CEO Welcome Home International Realty Group
                                    </h4>
                                </a>
                                <a href="https://www.linkedin.com/in/TyrellSmith" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/ty_smith.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Ty Smith<br /><br />
                                        Founder/CEO <br /> Optical Diversity
                                    </h4>
                                </a>
                            </div>
                            <div class='item'>

                                <a href="https://www.linkedin.com/in/johnsonxanthony/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Anthony_Johnson.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Anthony Johnson<br /><br />
                                        Corporate Strategy & Marketing
                                    </h4>
                                </a>
                                <a href="https://www.linkedin.com/in/timmetzner/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Tim_Metzner.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Tim Metzner<br /><br />
                                        CEO <br /> Fireroad
                                    </h4>
                                </a>
                            </div>
                            <div class='item'>

                                <a href="https://www.linkedin.com/in/megan-hymore/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Megan_Hymore.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Megan Hymore<br /><br />
                                        Venture Attorney<br /> Dinsmore & Shohl LLP
                                    </h4>
                                </a>
                                <a href="https://www.linkedin.com/in/link-green-31510314a/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Link_Green.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Link "The Khemist" Green<br /><br />
                                        Global Emerging Tech & Web3 Educator<br /> Coindesk Spokesperson & Ops Mgr $DESK
                                    </h4>
                                </a>
                            </div>
                            <div class='item'>

                                <a href="http://linkedin.com/in/fritzhand" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Jeremy_Fritzhand.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Jeremy Fritzhand<br /><br />
                                        Startup Catalyst
                                    </h4>
                                </a>
                                <a href="https://www.linkedin.com/in/jammontgomery/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Jamar_Montgomery.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Jamar “Doc” Montgomery<br /><br />
                                        Program Manager  <br /> Boeing
                                    </h4>
                                </a>
                            </div>
                            <div class='item'>

                                <a href="http://www.linkedin.com/in/matthew-sias-91b2a339" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Matthew_Sias.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Matthew Sias<br /><br />
                                        Founder<br /> InnovationAcceleration.ai
                                    </h4>
                                </a>
                                <a href="https://www.linkedin.com/in/claytonbanks/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Clayton_Banks.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Clayton Banks<br /><br />
                                        CEO <br /> Silicon Harlem
                                    </h4>
                                </a>
                            </div>
                            <div class='item'>

                                <a href="https://www.linkedin.com/in/mgleveille/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Genevieve_1.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Genevieve Leveille<br /><br />
                                        Founder/CEO <br /> AgriLedger
                                    </h4>
                                </a>
                                <a href="https://www.linkedin.com/in/deepaksekar/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Deepak.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Deepak Sekar<br /><br />
                                        Co-Founder/CEO<br /> Prof Jim
                                    </h4>
                                </a>
                            </div>
                            <div class='item'>

                                <a href="https://www.linkedin.com/in/consumergeneratedmedia/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/PeteBlackshaw.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Pete Blackshaw<br /><br />
                                        Founder/CEO<br /> BrandRank.AI
                                    </h4>
                                </a>
                                {/* <a href="https://www.linkedin.com/in/debbieareynolds/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/DebbieReynolds.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Debbie Reynolds “The Data Diva”<br /><br />
                                        Founder/CEO<br /> Debbie Reynolds Consulting
                                    </h4>
                                </a> */}
                                 <a href="https://www.linkedin.com/in/jessgem/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/JessicaGaston.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Jessica Gaston<br /><br />
                                        Founder<br />The Finance Doctor LLC
                                    </h4>
                                </a>
                            </div>
                            <div class='item'>

                               
                                <a href="https://www.linkedin.com/in/charron-leeper-58987714/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/CharronLeeper.jpeg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Charron Leeper<br /><br />
                                        Co-Founder/Creative Director<br />Futureland
                                    </h4>
                                </a>
                                <a href="#" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Jackson_III.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Learwinson Jackson III<br /><br />
                                    Executive Program Director <br /> The S.T.O.P. Squad All Boys Web3 & Blockchain Academy
                                    </h4>
                                </a>
                            </div>
                            <div class='item'>
                                <a href="https://www.linkedin.com/in/marquanjones31/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Marquan_Jones.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Marquan Jones<br /><br />
                                        President <br /> Chicago Fashion Coalition
                                    </h4>
                                </a>
                                <a href="https://www.linkedin.com/in/marwig/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Merry_Marwig.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Merry Marwig<br /><br />
                                    Co-Founder  <br /> The Privacy Exchange Community
                                    </h4>
                                </a>
                            </div>
                            <div class='item'>

                               
                                <a href="https://www.linkedin.com/in/dr-j-c-baker-51216b113/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Dr_J_C_Baker.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Dr. J.C. Baker<br /><br />
                                        Owner <br /> The Business Hospital
                                    </h4>
                                </a>
                                <a href="https://www.linkedin.com/in/johnbcreating/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/John_Johnson.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">John Johnson<br /><br />
                                        Founder/Principal & Identity Architect<br /> A Small Studio
                                    </h4>
                                </a>
                            </div>
                            {/* <div class='item'> */}

                                {/* <a href="https://www.linkedin.com/in/joshua-ogundu-b92b835b/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Joshua_Ogundu.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Joshua Ogundu<br /><br />
                                        Founder/CEO <br /> Tradeway
                                    </h4>
                                </a> */}
                               
                            {/* </div> */}
                            <div class='item'>

                                {/* <a href="http://www.linkedin.com/in/karora007" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Kumar_Arora.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Kumar Arora<br /><br />
                                        CEO<br /> Arora Ventures
                                    </h4>
                                </a> */}
                                <a href="https://www.linkedin.com/in/kalistabecoming/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Kalista_Zackhariyas.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Kalista Zackhariyas<br /><br />
                                        Founder/CEO<br /> Sparkseeker
                                    </h4>
                                </a>
                                <a href="https://www.linkedin.com/in/kemoaakhutera/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/K_akhutera.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Kémo A’akhutera<br /><br />
                                        Founder/CEO<br /> Trep House & Design To Build
                                    </h4>
                                </a>
                            </div>
                            <div class='item'>

                                
                                <a href="https://www.linkedin.com/in/camrynellis2/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Camyrn-Ellis.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Camryn Ellis<br /><br />
                                        Founder/CEO <br /> RYN
                                    </h4>
                                </a>
                                <a href="https://www.linkedin.com/in/iyanuodebode/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Iyanuoluwa_Odebode.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Iyanuoluwa Odebode<br /><br />
                                    CEO/Chief Data Scientist<br /> Zeitios
                                    </h4>
                                </a>
                            </div>
                            {/* <div class='item'> */}

                                {/* <a href="https://www.linkedin.com/in/acmceo/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Patrick_Hill.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Patrick Hill<br /><br />
                                        Founder/CEO <br /> Disctopia
                                    </h4>
                                </a> */}
                                
                            {/* </div> */}
                            <div class='item'>

                                <a href="https://www.linkedin.com/in/angeline-corvaglia-52b4a86/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/angeline.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Angeline Corvaglia<br /><br />
                                        Founder<br /> Data Girl and Friends
                                    </h4>
                                </a>
                                <a href="https://www.linkedin.com/in/john-delay-24ab5a/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/John_Delay.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">John Delay<br /><br />
                                        Founder/CEO<br /> Geospatial Sciences Services
                                    </h4>
                                </a>
                            </div>
                            <div class='item'>

                                <a href="https://www.linkedin.com/in/zaria-smith-0a31b6170?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Zaria_Smith.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Zaria Smith<br /><br />
                                        Program Director<br /> Nex Cubed
                                    </h4>
                                </a>
                                {/* <a href="https://www.linkedin.com/in/naama-o-pozniak-69b5913/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Naama_headshot.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Naama O. Pozniak<br /><br />
                                        Founder/CEO<br /> LetzGetPhygital
                                    </h4>
                                </a> */}
                                 <a href="https://www.linkedin.com/in/cyrus-harbin-6a0892163/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Cyrus.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Cyrus Harbin<br /><br />
                                        Founder<br /> Tech Is The New Black
                                    </h4>
                                </a>
                            </div>
                            <div class='item'>

                               
                                <a href="https://www.linkedin.com/in/marc-minor-a734887/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Marc_Minor.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Marc Minor<br /><br />
                                        Senior Counsel<br /> Thompson Hine LLP
                                    </h4>
                                </a>
                                <a href="https://www.linkedin.com/in/chrysatheodore/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Chrysa_Theodore.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Chrysa Theodore<br /><br />
                                        VP<br /> Venture Operations
                                    </h4>
                                </a>
                            </div>
                            <div class='item'>

                               
                                <a href="https://www.linkedin.com/in/ericinnyc/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Eric_Solomon.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Eric Solomon, Ph.d <br /><br />
                                        Founder/CEO<br /> The Human OS™
                                    </h4>
                                </a>
                                <a href="https://www.linkedin.com/in/damuwinston/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Damu_Winston.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Damu Winston<br /><br />
                                        Global Head Web 3<br /> Amazon
                                    </h4>
                                </a>
                            </div>
                            <div class='item'>
                                
                                <a href="https://www.linkedin.com/in/lenajjennings/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Lena_Jennings.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Lena Jennings<br /><br />
                                        Data Story Teller<br /> Google
                                    </h4>
                                </a>
                                <a href="http://linkedin.com/in/adetayoadesanya" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Tayo_Adesanya.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Tayo Adesanya<br /><br />
                                    Founder/CEO<br />Lola Vision Systems
                                    </h4>
                                </a>
                            </div>
                            <div class='item'>
                               
                                <a href="http://linkedin.com/in/tracyslee" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/tracy_lee.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Tracy Lee<br /><br />
                                    CEO<br /> This Dot Labs
                                    </h4>
                                </a>
                                <a href="https://www.linkedin.com/in/borisoglesby/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Boris_Oglesby.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Boris Oglesby<br /><br />
                                    Executive VP & Practice Leader<br />Circana
                                    </h4>
                                </a>
                            </div>
                            <div class='item'>
                               
                                <a href="https://www.linkedin.com/in/maximserezhin/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/Maxim_Serezhin.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Maxim Serezhin<br /><br />
                                    Founder/CEO<br /> Standard Power
                                    </h4>
                                </a>
                                <a href="https://www.linkedin.com/in/rodneybwilliams/" target="_blank" className="psitem">
                                    <div className="psitemimg">
                                        <img src={'/assets/img/ps2024/rodney_williams.jpg'} alt="" className="imgcenterimg" />
                                    </div>
                                    <h4 className="clrfffbf9 fst-italic text-center">Rodney Williams<br /><br />
                                    Co-Founder<br /> Solo Funds
                                    </h4>
                                </a>
                            </div>
                        </OwlCarousel>
                    </div>

                    <div className="speaker_lineup _mobile">
                        <SpeakerLineup itemsToShowInitially={8} itemsPerLoad={100} items={items} />
                    </div>

                </div>
            </div>

            <div className="py60">
                <div className="container">
                    <h4 className="pfdf40 pfdf45 mb50 fst-italic" data-aos="fade-up" data-aos-duration="1000">PREVIOUS <span className="clrfffbf9">SPONSORS</span></h4>
                    <OwlCarousel className='owl-theme whiteimg' loop margin={0}  {...options}>
                        <div class='item'>
                            <a href="https://www.53.com/content/fifth-third/en.html" target="_blank">
                                <img src={'/assets/img/Fifth_Third_Bank.png'} alt="" className="mw-100" />
                            </a>
                        </div>
                        <div class='item'>
                            <a href="https://www.uc.edu/" target="_blank">
                                <img src={'/assets/img/cincinnati.png'} alt="" className="mw-100" />
                            </a>
                        </div>
                        <div class='item'>
                            <a href="https://www.jobsohio.com/" target="_blank">
                                <img src={'/assets/img/jobs-ohio.png'} alt="" className="mw-100" />
                            </a>
                        </div>
                        <div class='item'>
                            <a href="https://standardpwr.com/" target="_blank">
                                <img src={'/assets/img/SP_LogoUpdate_090221.png'} alt="" className="mw-100" />
                            </a>
                        </div>
                        <div class='item'>
                            <a href="https://innovation.uc.edu/" target="_blank">
                                <img src={'/assets/img/1819logo.png'} alt="" className="mw-100" />
                            </a>
                        </div>
                        <div class='item'>
                            <a href="https://www.grey.com/" target="_blank">
                                <img src={'/assets/img/grey.png'} alt="" className="mw-100" />
                            </a>
                        </div>
                        <div class='item'>
                            <a href="https://ucdigitalfutures.com/" target="_blank">
                                <img src={'/assets/img/digital-fuitures.png'} alt="" className="mw-100" />
                            </a>
                        </div>
                        <div class='item'>
                            <a href="https://weareagnt.com/" target="_blank">
                                <img src={'/assets/img/agnt.png'} alt="" className="mw-100" />
                            </a>
                        </div>
                        <div class='item'>
                            <a href="https://www.qca.com/" target="_blank">
                                <img src={'/assets/img/angels.png'} alt="" className="mw-100" />
                            </a>
                        </div>
                        <div class='item'>
                            <a href="https://www.greatamericaninsurancegroup.com/" target="_blank">
                                <img src={'/assets/img/American_Financial_Group_Logo.svg.png'} alt="" className="mw-100" />
                            </a>
                        </div>
                        <div class='item'>
                            <a href="https://www.coinbase.com/" target="_blank">
                                <img src={'/assets/img/Coinbase_logo.png'} alt="" className="mw-100" />
                            </a>
                        </div>
                        <div class='item'>
                            <a href="https://cybersurellc.com/" target="_blank">
                                <img src={'/assets/img/cybersure_logo.png'} alt="" className="mw-100" />
                            </a>
                        </div>
                        <div class='item'>
                            <a href="https://www.dinsmore.com/" target="_blank">
                                <img src={'/assets/img/Dinsmore-2c-print.png'} alt="" className="mw-100" />
                            </a>
                        </div>
                        <div class='item'>
                            <a href="https://www.3cdc.org/" target="_blank">
                                <img src={'/assets/img/3cdc.png'} alt="" className="mw-100" />
                            </a>
                        </div>
                        <div class='item'>
                            <a href="https://cintrifuse.com/" target="_blank">
                                <img src={'/assets/img/Cintrifuse.png'} alt="" className="mw-100" />
                            </a>
                        </div>
                        <div class='item'>
                            <a href="https://bsl.group/" target="_blank">
                                <img src={'/assets/img/bslgroup.png'} alt="" className="mw-100" />
                            </a>
                        </div>
                        <div class='item'>
                            <a href="https://www.ama.org/" target="_blank">
                                <img src={'/assets/img/amacincin_logo.png'} alt="" className="mw-100" />
                            </a>
                        </div>
                        <div class='item'>
                            <a href="https://www.richieschicken.com/#intro" target="_blank">
                                <img src={'/assets/img/Richies_Chicken_and_Soul.png'} alt="" className="mw-100" />
                            </a>
                        </div>
                        <div class='item'>
                            <a href="https://www.makersmark.com/" target="_blank">
                                <img src={'/assets/img/markers_logo2.png'} alt="" className="mw-100" />
                            </a>
                        </div>
                        <div class='item'>
                            <a href="#" target="_blank">
                                <img src={'/assets/img/cyrannus_logo.png'} alt="" className="mw-100" />
                            </a>
                        </div>
                    </OwlCarousel>

                    <div className="btnwithtxt pt60">
                        <h4 className="pfdf40 pfdf36 me-5" data-aos="fade-down" data-aos-duration="1000"><span className="clrfffbf9 fst-italic">Apply</span> to be a</h4>
                        <PopupButton id="Ruqoa1oW" style={{ fontSize: 20 }} className="btn btn-primary btn-custom animated animatedFadeInUp fadeInUp" data-aos="fade-up">
                            <span data-aos="fade-up" data-aos-duration="1500">SPONSOR</span>
                        </PopupButton>
                    </div>
                </div>
            </div>


            <div className="py60">
                <div className="container">
                    <h4 className="pfdf40 pfdf45 mb50 clrfffbf9" data-aos="fade-up" data-aos-duration="1000">Our <span className=" fst-italic">Three Pillars</span></h4>

                    <div className="otpwrap" >
                        <div className="tpimgwrap" data-aos="fade-down" data-aos-duration="1000">
                            <img src={'/assets/img/mwclogo.png'} alt="" />
                        </div>
                        {/* <Accordion className="new-accor" data-aos="fade-up" data-aos-duration="1000">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Policy innovation</Accordion.Header>
                                <Accordion.Body>
                                    Policy and regulation have never mattered more in the current age of technological advancements. Open discussions and authentic collaborations are a must, so we connect the best people in policy with the builders of our future. No fluff, no redtapes. Just open discussions and a hunger for change.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Creator Economy</Accordion.Header>
                                <Accordion.Body>
                                    Creators drive our culture. We want to empower creative visionaries who define our cultural landscape by opening and sharing our network with them. They deserve the recognition and the stage– and we’re giving it to them.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Social Impact</Accordion.Header>
                                <Accordion.Body>
                                    Technologies hold the potential to redefine our society, we know that. Yet we don’t always talk about where we can direct these advancements towards social good and the betterment of our workforces. We’re changing that.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion> */}
                        <Accordion className="new-accor" data-aos="fade-up" data-aos-duration="1000">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Artistic Intelligence</Accordion.Header>
                                <Accordion.Body>
                                    <p>The real AI is not artificial intelligence; it's artistic intelligence. AI is a tool that should be shaped by the artists, the builders, the creators, or the curators. It's about empowering humanity & authenticity.  Areas of focus include</p>
                                    <ul>
                                        <li>The Future of Marketing & branding</li>
                                        <li>Creator ownership and rights in the age of AI</li>
                                        <li>User center design</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Policy Innovation</Accordion.Header>
                                <Accordion.Body>
                                    <p>Policy and regulation have never mattered more in the current age of technological advancements. Open discussions and authentic collaborations are necessary to connect the best people in policy with the builders of our future. Areas of focus include</p>
                                    <ul>
                                        <li>Responsible AI- national experts panels and workshops on why transparent and trustworthy AI will mean more growth.</li>
                                        <li>Data privacy and Digital Safety- how data privacy and digital safety affect our most vulnerable populations and strategies for protecting our children and other vulnerable populations.</li>
                                        <li>Policies around Blockchain and fintech innovations—the future of commerce will not be the outdated systems that limit access.</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Tech for Social Impact</Accordion.Header>
                                <Accordion.Body>
                                    <p>Technologies hold the potential to redefine our society; we know that. Yet we don’t always discuss where we can direct these advancements towards social good and improving our workforces. We’re changing that. Areas of focus include</p>
                                    <ul>
                                        <li>Workforce development and AI</li>
                                        <li>Sustainability and emerging tech</li>
                                        <li>Education/research and public-private partnerships</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>

                </div>
            </div>

            <div className="commmunitywrap">
                <div className="commmunityimg" data-aos="fade-down" data-aos-duration="1000"><img src={'/assets/img/commu.png'} alt="" /></div>

                <div className="container">
                    {/* <div className="commmunitycontent" data-aos="fade-up" data-aos-duration="1000">
                        <div className="mw845">
                            we want every<br /> conversation to point<br /> towards what<br /> really matters:
                        </div>
                        <div className="mw845 mt95 clrfffbf9 text-end mw-auto pe-275">
                            <a href="https://www.eventbrite.com/e/midwestcon-2024-tickets-710598889407" target="_blank" className="cmt_btn">
                                <span> community.</span>
                            </a>
                        </div>
                    </div>
                    <div className="commmunityarrow" data-aos="fade-down" data-aos-duration="1000">
                        <img src={'/assets/img/down-arrow.png'} alt="" />
                    </div> */}

                    <div className="hover_sec">
                        <h2>What’s in it for me?</h2>
                        <div class="hoverme">
                            <p><span>Learning & Training</span>
                                <div class="pop">
                                    <p>Gain knowledge through our intensive hands-on workshops with experts in building your own Open AI Chatbot without coding, Building apps in AP, mastering customer support conversation with Open AI, Automated Machine Learning, Marketing & AI, Accounting & AI, Blockchain, design thinking, and how to leverage university and private partnerships for your business.</p>
                                </div>
                            </p>
                        </div>
                        <div class="hoverme">
                            <p><span>Connections</span>
                                <div class="pop">
                                    <p>Network with CIOs, CMOs, and investors to find your next deal and partnership opportunities.</p>
                                </div>
                            </p>
                        </div>
                        <div class="hoverme">
                            <p><span>Jobs</span>
                                <div class="pop">
                                    <p>Meet with potential employers in technology and marketing fields.</p>
                                </div>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ft_btns brownbgsec" id="get">
                <div className="container">
                    <div className="btns">
                        <PopupButton id="OT6PQTFD" style={{ fontSize: 20 }} className="btn btn-primary btn-custom animated animatedFadeInUp fadeInUp disabled" data-aos="fade-up">
                            <span data-aos="fade-up" data-aos-duration="1500">SPEAKER</span>
                        </PopupButton>
                        <PopupButton id="Ruqoa1oW" style={{ fontSize: 20 }} className="btn btn-primary btn-custom animated animatedFadeInUp fadeInUp" data-aos="fade-up">
                            <span data-aos="fade-up" data-aos-duration="1500">SPONSOR</span>
                        </PopupButton>
                        <PopupButton id="gYZmAIv4" style={{ fontSize: 20 }} className="btn btn-primary btn-custom animated animatedFadeInUp fadeInUp" data-aos="fade-up">
                            <span data-aos="fade-up" data-aos-duration="1500">VOLUNTEER</span>
                        </PopupButton>
                    </div>
                </div>
            </div>

            <Footer />


        </div>
    );
}

export { NewMidwestcon };
