import React from "react";
import moment from 'moment';
import { PopupButton } from '@typeform/embed-react'

function Footer() {
      const currentYear = moment().year()
      return (



            <div className="mwc-footer">
                  <div className="container">
                        <div className="row align-items-center">                             
                              <div className='col-sm-12'>
                                    <div className="socialdevlop">
                                          <div className='mwc-social-links'>

                                                {/* <a className="nav-link" href="https://www.instagram.com/disruptnow_io/" target="_blank" rel="noreferrer"><i className="fa-brands fa-instagram"></i></a>
                                                <a className="nav-link" href="https://www.facebook.com/profile.php?id=100081213644945" target="_blank" rel="noreferrer"><i class="fa-brands fa-facebook-f"></i></a> */}
                                                <a className="nav-link" href="https://twitter.com/MidwestCon_" target="_blank" rel="noreferrer"><i class="fa-brands fa-x-twitter"></i></a>

                                          </div>
                                          <div className="copyrights">
                                          Copyright &copy; 2021 - {currentYear} All Rights Reserved by DISRUPT ART, INC.  | <a href="https://disrupt.art/nft/terms/" target="_blank">Terms &amp; Conditions</a> | <a href="https://disrupt.art/nft/privacy/" target="_blank">Privacy Policy</a>
                                    </div>

                                          <div className="walletlinks">
                                                <ul className="footer-links builtwallet">
                                                      <li className="developlink">
                                                            {/* <a target="_blank" href="https://disruptnow.io/">
                                                                  <span className="devlotxt">
                                                                        Powered by
                                                                  </span>
                                                                  <img src={'/assets/img/disruption-now.png'} alt="" className="onflowicon" />
                                                            </a> */}
                                                      </li>
                                                      <li className="developlink">
                                                            <a target="_blank" href="https://blaze.ws/">
                                                                  <img src={'/assets/img/blaze-logo.png'} alt="" className="onflowicon" />
                                                                  <span className="devlotxt">
                                                                        Developed at
                                                                        <span>BLA<span className="text-danger">Z</span>E.ws</span>
                                                                  </span>
                                                            </a>
                                                      </li>
                                                      <li className="developlink">
                                                            <a href="https://weareagnt.com/" target="_blank">
                                                                  <img src={'/assets/img/agnt2.png'} alt="" className="agnt_logo" />
                                                                  <span className="devlotxt">
                                                                  Designed by
                                                                        <span>AGNT</span>
                                                                  </span>
                                                            </a>
                                                      </li>
                                                </ul>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>


      );
}

export { Footer };
